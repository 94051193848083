body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.root {
  height: 100vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  position: relative;
  display: flex;
}

.page {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  position: absolute;
  overflow: auto;
  top: 0;
  bottom: 0;
  left: 0;
}

.container {
  overflow: auto;
  position: relative;
  min-height: min-content;
  flex-grow: 1;
  display: flex;
}

.padding {
  padding: 20px;
  height: calc(100% - 40px);
  width: calc(100% - 40px);
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.noPadding {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.cardHeader {
  padding: 25px;
  position: absolute;
  margin-top: -50px;
  margin-left: -5px;
}

.card {
  padding: 20px;
  margin-top: 40px;
  position: relative;
}
